import React from 'react'
import { QueryBuilder, ValueEditor } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.css'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { RiGitBranchLine } from 'react-icons/ri'
import { BiTrash } from 'react-icons/bi'
import FLabel from '../../../components/FLabel'

const fields = [
  {
    name: 'c.completed',
    label: 'حالة البيانات',
    type: 'boolean',
    operators: [{ name: '=', label: 'is' }],
    valueSources: ['value'],
    valueEditorType: 'radio',
    inputType: 'radio',
    values: [
      { name: 'True', label: 'مكتمل', value: true },
      { name: 'False', label: 'غير مكتمل', value: false },
    ],
  },
  {
    name: 'c.documentNumber',
    label: 'حالة الوثيقة',
    type: 'boolean',
    operators: [{ name: '=', label: '=' }],
    valueSources: ['value'],
    valueEditorType: 'radio',
    inputType: 'radio',
    values: [
      { name: 'True', label: 'يحتوي علي وثيقة', value: true },
      { name: 'False', label: 'لا يحتوي علي وثيقة', value: false },
    ],
  },
  {
    name: 'c.isExportedToIssuerReportBefore',
    label: 'تم الارسال للتأمين',
    type: 'boolean',
    operators: [{ name: '=', label: '=' }],
    valueSources: ['value'],
    valueEditorType: 'radio',
    inputType: 'radio',
    values: [
      { name: 'True', label: 'تم الارسال للتأمين', value: true },
      { name: 'False', label: 'لم تم الارسال للتأمين', value: false },
    ],
  },
  {
    name: 'c.isExportedToIssuerReportBefore',
    label: 'تم التصدير',
    type: 'boolean',
    operators: [{ name: '=', label: 'is' }],
    valueSources: ['value'],
    valueEditorType: 'radio',
    inputType: 'radio',
    values: [
      { name: 'True', label: 'نعم', value: true },
      { name: 'False', label: 'لا', value: false },
    ],
  },
  {
    name: 'c.productId',
    label: 'المنتج',
    type: 'select',
    operators: [{ name: '=', label: '=' }],
    valueSources: ['value'],
    valueEditorType: 'select',
    inputType: 'select',
    values: JSON.parse(localStorage.getItem('products'))
      ?.filter(
        product =>
          product.name.toLowerCase() !== 'Merchant Cancellation'.toLowerCase(),
      )
      .map(product => ({
        name: product.id,
        label: product.name,
        value: product.id,
      })),
  },

  { name: 'c.suspended', label: 'معلق' },
  {
    name: 'c.machineId',
    label: 'رقم الجهاز',
    inputType: 'text',
    operators: [{ name: '=', label: '=' }],
  },
  {
    name: 'c.fawryCode',
    label: 'كود فوري',
    inputType: 'text',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' },
      { name: 'null', label: 'لا يحتوي علي كود فوري' },
    ],
  },
  {
    name: 'c.customerCompletedTeam',
    label: 'الفريق',
    type: 'text',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' },
      { name: 'null', label: 'لا يحتوي علي فريق' },
    ],
  },
  {
    name: 'c.dateOFLastPaidInstallment',
    label: 'تاريخ اخر قسط مدفوع',
    inputType: 'date',
    operators: [
      { name: '<', label: '<' },
      { name: '>', label: '>' },
      { name: '<=', label: '<=' },
      { name: '>=', label: '>=' },
      { name: '=', label: '=' },
      { name: 'notNull', label: 'مدفوع' },
      { name: 'null', label: 'غير مدفوع' },
    ],
    valueSources: ['value'],
    valueEditorType: 'date',
  },
  {
    name: 'c.gigResponseUploadDate',
    label: 'تاريخ رفع ملف الوثايق',
    inputType: 'date',
    operators: [
      { name: '<', label: '<' },
      { name: '>', label: '>' },
      { name: '<=', label: '<=' },
      { name: '>=', label: '>=' },
      { name: '=', label: '=' },
    ],
    valueSources: ['value'],
    valueEditorType: 'date',
  },

  {
    name: 'c.completeCustomerDataDate',
    label: 'تاريخ اكمال بيانات العميل',
    inputType: 'date',
    operators: [
      { name: '<', label: '<' },
      { name: '>', label: '>' },
      { name: '<=', label: '<=' },
      { name: '>=', label: '>=' },
      { name: '=', label: '=' },
      { name: 'notNull', label: 'مكتمل' },
      { name: 'null', label: 'غير مكتمل' },
    ],
    valueSources: ['value'],
    valueEditorType: 'date',
  },
  {
    name: 'c.creationDate',
    label: 'تاريخ الانشاء',
    inputType: 'date',
    operators: [
      { name: '<', label: '<' },
      { name: '>', label: '>' },
      { name: '<=', label: '<=' },
      { name: '>=', label: '>=' },
    ],
    valueSources: ['value'],
    valueEditorType: 'date',
  },
  { name: 'c.cancellationDate', label: 'تاريخ الالغاء' },
  { name: 'c.suspendedDate', label: 'تاريخ التعليق' },
]

const QBuilder = ({ query, setQuery }) => {
  return (
    <div className={'w-full'}>
      <QueryBuilder
        controlElements={{
          valueEditor: props => {
            if (props.field === 'c.completed') {
              return (
                <div className={'flex items-center gap-1'}>
                  <input
                    type='radio'
                    id={'completed'}
                    name={'completed'}
                    checked={props.value === true}
                    onChange={e => props.handleOnChange(!props.value)}
                  />
                  <FLabel htmlFor={'completed'}>مكتمل</FLabel>
                  <input
                    type='radio'
                    name={'notCompleted'}
                    id={'notCompleted'}
                    checked={props.value === false}
                    onChange={e => props.handleOnChange(!props.value)}
                  />
                  <FLabel htmlFor={'notCompleted'}>غير مكتمل</FLabel>
                </div>
              )
            } else {
              return <ValueEditor {...props} />
            }
          },
          addGroupAction: ({ label, className, handleOnClick }) => (
            <FButton
              className={className}
              onClick={handleOnClick}
              style={{ width: '100%' }}
            >
              اضافة مجموعة
            </FButton>
          ),
          removeGroupAction: ({ label, className, handleOnClick }) => (
            <FButton onClick={handleOnClick} style={{ width: '100%' }}>
              {label}
            </FButton>
          ),
          addRuleAction: ({ label, className, handleOnClick }) => (
            <FButton
              className={className}
              onClick={handleOnClick}
              style={{ width: '100%' }}
            >
              <FIconWrapper>
                <RiGitBranchLine />
                اضافة شرط
              </FIconWrapper>
            </FButton>
          ),
          removeRuleAction: ({ label, className, handleOnClick }) => (
            <FButton
              btnType={'danger'}
              className={'w-fit'}
              onClick={handleOnClick}
            >
              <FIconWrapper>
                <BiTrash />
              </FIconWrapper>
            </FButton>
          ),
        }}
        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
        showCombinatorsBetweenRules={true}
        fields={fields}
        query={query}
        onQueryChange={q => setQuery(q)}
      />
    </div>
  )
}
export default QBuilder
