import FModal from '../../../components/FModal'
import FInputField from '../../../components/FInputField'
import Select from 'react-select'
import QBuilder from '../Subscriptions/QBuilder'
import FButton from '../../../components/FButton'
import React, { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { formatQuery } from 'react-querybuilder'

const FilterModal = ({ isOpen, setIsOpen, query, setQuery }) => {
  const [extraEmailSend, setExtraEmailSend] = useState('')
  const [extraKeys, setExtraKeys] = useLocalStorage('extraKeys', [])
  const [spreadSheetScanner, setSpreadSheetScanner] = useState('')

  const str = formatQuery(query, 'sql')

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setSpreadSheetScanner(e.target.files[0])
    }
  }

  const handleDownload = () => {
    const formData = new FormData()
    formData.append('file', spreadSheetScanner)
    formData.append(
      'extraFields',
      JSON.stringify({
        keys: extraKeys.map(key => key.value),
      }),
    )
    axiosInstance
      .post('/report/loadCustomerSubscriptions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
      .then(response => {
        const modifiedFile = response.data

        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)

        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl
        downloadLink.download = 'تقرير الاشتراكات.xlsx'

        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)

        toast.success('تم رفع الملف بنجاح')
      })
      .catch(err => {
        toast('حدث خطأ ما')
      })
  }

  const reportName = 'تقرير الاشتراكات بتاريخ ' + new Date().toISOString()

  const extraKeysString = JSON.stringify({
    keys: extraKeys.map(key => key.value),
  })
  const handleDownloadFilteredList = () => {
    axiosInstance
      .get(
        `customersubscription/sendAllWithQueryParam?queryParam=${str.substring(
          1,
          str.length - 1,
        )}&reportName=${reportName} ${
          extraEmailSend !== ''
            ? `&emailToReceiveTheReport=${extraEmailSend}`
            : ''
        }&extraFields=${encodeURIComponent(extraKeysString)}`,
        {
          responseType: 'blob',
        },
      )
      .then(res => {
        toast('تم ارسال الايميل بنجاح')
      })
  }
  return (
    <>
      <FModal isOpen={isOpen} setIsOpen={setIsOpen} title={'فلترة'}>
        <div className={'flex flex-col gap-2'}>
          <div className={'flex flex-col items-start gap-2'}>
            <FInputField
              placeholder={'ايميل اضافي للتنزيل'}
              value={extraEmailSend}
              onChange={e => {
                setExtraEmailSend(e.target.value)
              }}
            />
            <FInputField type='file' onChange={handleFileChange} />
            <Select
              isMulti
              className={'w-full'}
              options={[
                {
                  label: 'تم الإجابة من تحميل العميل',
                  value: 'answersCompletedFromCustomerUpload',
                },
                {
                  label: 'فريق اكمال البيانات',
                  value: 'customerCompletedTeam',
                },
                {
                  label: 'id',
                  value: 'id',
                },
                {
                  label: 'gender',
                  value: 'gender',
                },
                {
                  label: 'documentNumber',
                  value: 'documentNumber',
                },
                { label: 'تاريخ الإلغاء', value: 'cancellationDate' },
                { label: 'ملاحظات الإلغاء', value: 'cancellationNotes' },
                { label: 'سبب الإلغاء', value: 'cancellationReason' },
                { label: 'تم الإلغاء', value: 'cancelled' },
                {
                  label: 'تم تأكيد الإلغاء من Gig',
                  value: 'cancelledConfirmedFromGig',
                },
                {
                  label: 'تاريخ استكمال بيانات العميل',
                  value: 'completeCustomerDataDate',
                },
                { label: 'تم الاستكمال', value: 'completed' },
                { label: 'تم إنشاءه بواسطة', value: 'createdBy' },
                { label: 'تم الإنشاء من', value: 'createdFrom' },
                {
                  label: 'تم الإنشاء من تحميل العميل',
                  value: 'createdFromCustomerUpload',
                },
                { label: 'تم الإنشاء من Oracle', value: 'createdFromOracle' },
                { label: 'تاريخ الإنشاء', value: 'creationDate' },
                {
                  label: 'تاريخ رفع ملف الوثايق',
                  value: 'gigResponseUploadDate',
                },
                {
                  label: 'attachmentNumberForCover',
                  value: 'attachmentNumberForCover',
                },
                {
                  label: 'cancellationDate',
                  value: 'cancellationDate',
                },
              ]}
              value={extraKeys}
              onChange={setExtraKeys}
            />

            <QBuilder query={query} setQuery={setQuery} />

            <div className={'mt-2 flex gap-2'}>
              <FButton onClick={handleDownload}>
                <span> تنزيل البينات باستخدام ملف اكسيل</span>
              </FButton>
              {str !== '(1 = 1)' && (
                <FButton
                  onClick={() => {
                    handleDownloadFilteredList()
                  }}
                >
                  تنزيل النتائج
                </FButton>
              )}

              <FButton
                btnType={'secondary'}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                اغلاق
              </FButton>
            </div>
          </div>
        </div>
      </FModal>
    </>
  )
}
export default FilterModal
